import axios from 'axios';
import Urls from '../../../../constant/urls';
import { handleNotificationsResponse } from '../../../../common/notifications/notifications-action';
import { batch } from 'react-redux';
import { prepareBrandImgUrl, prepareModelImgUrl } from './helpers';

export const ADD_NEW_BRANDS = 'ADD_NEW_BRANDS';
export const SET_SELECTED_BRAND = 'SET_SELECTED_BRAND';
export const DELETE_BRAND = 'DELETE_BRAND';
export const UPDATE_BRANDS = 'UPDATE_BRANDS';

export const ADD_NEW_MODELS = 'ADD_NEW_MODELS';
export const SET_SELECTED_MODEL = 'SET_SELECTED_MODEL';
export const DELETE_MODEL = 'DELETE_MODEL';
export const UPDATE_MODEL = 'UPDATE_MODEL';

export const ADD_SERVICE_DESCRIPTIONS = 'ADD_SERVICE_DESCRIPTIONS';
export const SET_SELECTED_SERVICE_DESCRIPTION = 'SET_SELECTED_SERVICE_DESCRIPTION';
export const UPDATE_SERVICE_DESCRIPTION = 'UPDATE_SERVICE_DESCRIPTION';
export const DELETE_SERVICE_DESCRIPTION = 'DELETE_SERVICE_DESCRIPTION';

export const ADD_NEW_SERVICES = 'ADD_NEW_SERVICES';
export const SET_SELECTED_SERVICE = 'SET_SELECTED_SERVICE';
export const UPDATE_SERVICE = 'UPDATE_SERVICE';
export const DELETE_SERVICE = 'DELETE_SERVICE';

export const SET_PRICE_LIST_DRAWER_MODE = 'SET_PRICE_LIST_DRAWER_MODE';

export const TOGGLE_PRICE_LIST_DRAWER = 'TOGGLE_PRICE_LIST_DRAWER';

//
// BRANDS
//

export const addNewBrand = ({ Name, ImgInfo }) => async (dispatch) => {
    const [response, data] = await axios
        .post(
            Urls.AddNewBrands,
            [
                {
                    Name,
                    ImgInfo,
                    ImgUrl: prepareBrandImgUrl(Name),
                },
            ],
            {
                headers: {
                    'x-auth-token': localStorage.getItem('authenticationToken'),
                },
            }
        )
        .then((res) => [res, res?.data])
        .catch((err) => [err]);

    if (response?.status === 200) {
        dispatch(addNewBrands(data.payload));
    }

    dispatch(handleNotificationsResponse(response));
};

export const getBrands = () => async (dispatch) => {
    const [response, data] = await axios
        .get(Urls.GetAdminBrands, {
            headers: {
                'x-auth-token': localStorage.getItem('authenticationToken'),
            },
        })
        .then((res) => [res, res?.data])
        .catch((err) => [err]);

    if (response?.status === 200) {
        dispatch(addNewBrands(data));
    } else {
        dispatch(handleNotificationsResponse(response));
    }
};

export const updateBrand = ({ Id, Name, ImgInfo, ImgUrl }) => async (dispatch) => {
    const [response, data] = await axios
        .put(
            `${Urls.EditBrands}/${Id}`,
            { Name, ImgInfo, ImgUrl },
            {
                headers: {
                    'x-auth-token': localStorage.getItem('authenticationToken'),
                },
            }
        )
        .then((res) => [res, res?.data?.payload])
        .catch((err) => [err]);

    if (response?.status === 200) {
        dispatch(replaceBrand(data));
    }

    dispatch(handleNotificationsResponse(response));
};

export const removeBrand = (brandId) => async (dispatch) => {
    const [response, data] = await axios
        .delete(`${Urls.DeleteBrands}/${brandId}`, {
            headers: {
                'x-auth-token': localStorage.getItem('authenticationToken'),
            },
        })
        .then((res) => [res, res?.data.payload[0]])
        .catch((err) => [err]);

    if (response?.status === 200) {
        dispatch(deleteBrand(data.Id));
    }
    dispatch(handleNotificationsResponse(response));
};

const addNewBrands = (brands) => ({
    type: ADD_NEW_BRANDS,
    payload: brands,
});

const replaceBrand = (brands) => ({
    type: UPDATE_BRANDS,
    payload: brands,
});

const deleteBrand = (brandId) => ({
    type: DELETE_BRAND,
    payload: brandId,
});

export const setSelectedBrandId = (brandId) => ({
    type: SET_SELECTED_BRAND,
    payload: brandId,
});

//
// MODELS
//
export const addNewModel = ({ Name, ImgInfo, brand }) => async (dispatch) => {
    const [response, data] = await axios
        .post(
            Urls.AddNewModels,
            [
                {
                    Name,
                    ImgInfo,
                    ImgUrl: prepareModelImgUrl(Name, brand?.Name),
                    Brand: brand?.Id,
                },
            ],
            {
                headers: {
                    'x-auth-token': localStorage.getItem('authenticationToken'),
                },
            }
        )
        .then((res) => [res, res?.data])
        .catch((err) => [err]);
    if (response?.status === 200) {
        dispatch(addNewModels(data.payload));
    }

    dispatch(handleNotificationsResponse(response));
};

export const getAdminModels = (brandId) => async (dispatch) => {
    const [response, data] = await axios
        .get(`${Urls.GetAdminModels}/${brandId}`, {
            headers: {
                'x-auth-token': localStorage.getItem('authenticationToken'),
            },
        })
        .then((res) => [res, res?.data])
        .catch((err) => [err]);

    if (response?.status === 200) {
        dispatch(addNewModels(data));
    } else {
        dispatch(handleNotificationsResponse(response));
    }
};

export const updateModel = ({ Id, Name, ImgInfo, ImgUrl }) => async (dispatch) => {
    const [response, data] = await axios
        .put(
            `${Urls.EditModels}/${Id}`,
            { Name, ImgInfo, ImgUrl },
            {
                headers: {
                    'x-auth-token': localStorage.getItem('authenticationToken'),
                },
            }
        )
        .then((res) => [res, res?.data?.payload])
        .catch((err) => [err]);

    if (response?.status === 200) {
        dispatch(replaceModel(data));
    }

    dispatch(handleNotificationsResponse(response));
};

export const removeModel = (modelId) => async (dispatch) => {
    const [response, data] = await axios
        .delete(`${Urls.DeleteModels}/${modelId}`, {
            headers: {
                'x-auth-token': localStorage.getItem('authenticationToken'),
            },
        })
        .then((res) => [res, res?.data?.payload[0]])
        .catch((err) => [err]);

    if (response?.status === 200) {
        dispatch(deleteModel(data.Id));
    }
    dispatch(handleNotificationsResponse(response));
};

const addNewModels = (models) => ({
    type: ADD_NEW_MODELS,
    payload: models,
});

const replaceModel = (model) => ({
    type: UPDATE_MODEL,
    payload: model,
});

const deleteModel = (modelId) => ({
    type: DELETE_MODEL,
    payload: modelId,
});

export const setSelectedModelId = (modelId) => ({
    type: SET_SELECTED_MODEL,
    payload: modelId,
});

//
//SERVICE DESCRIPTION
//
export const addNewServiceDescription = ({ Description }) => async (dispatch) => {
    const [response, data] = await axios
        .post(
            Urls.AddNewServiceDescriptions,
            [
                {
                    Description,
                },
            ],
            {
                headers: {
                    'x-auth-token': localStorage.getItem('authenticationToken'),
                },
            }
        )
        .then((res) => [res, res?.data.payload])
        .catch((err) => [err]);

    if (response?.status === 200) {
        dispatch(addNewServiceDescriptions(data));
    }

    dispatch(handleNotificationsResponse(response));
};

export const getServiceDescriptions = () => async (dispatch) => {
    const [response, data] = await axios
        .get(Urls.GetAdminServiceDescriptions, {
            headers: {
                'x-auth-token': localStorage.getItem('authenticationToken'),
            },
        })
        .then((res) => [res, res?.data])
        .catch((err) => [err]);

    if (response?.status === 200) {
        dispatch(addNewServiceDescriptions(data));
    } else {
        dispatch(handleNotificationsResponse(response));
    }
};

export const updateServiceDescription = ({ Id, Description }) => async (dispatch) => {
    const [response, data] = await axios
        .put(
            `${Urls.EditServiceDescriptions}/${Id}`,
            { Id, Description },
            {
                headers: {
                    'x-auth-token': localStorage.getItem('authenticationToken'),
                },
            }
        )
        .then((res) => [res, res?.data?.payload])
        .catch((err) => [err]);

    if (response?.status === 200) {
        dispatch(replaceServiceDescription(data));
    }

    dispatch(handleNotificationsResponse(response));
};

export const removeServiceDescription = (serviceDescriptionId) => async (dispatch) => {
    const [response, data] = await axios
        .delete(`${Urls.DeleteServiceDescriptions}/${serviceDescriptionId}`, {
            headers: {
                'x-auth-token': localStorage.getItem('authenticationToken'),
            },
        })
        .then((res) => [res, res?.data?.payload[0]])
        .catch((err) => [err]);

    if (response?.status === 200) {
        dispatch(deleteServiceDescription(data.Id));
    }
    dispatch(handleNotificationsResponse(response));
};

const addNewServiceDescriptions = (serviceDescriptions) => ({
    type: ADD_SERVICE_DESCRIPTIONS,
    payload: serviceDescriptions,
});

const replaceServiceDescription = (serviceDescription) => ({
    type: UPDATE_SERVICE_DESCRIPTION,
    payload: serviceDescription,
});

const deleteServiceDescription = (serviceDescriptionId) => ({
    type: DELETE_SERVICE_DESCRIPTION,
    payload: serviceDescriptionId,
});

export const setSelectedServiceDescriptionId = (serviceDescriptionId) => ({
    type: SET_SELECTED_SERVICE_DESCRIPTION,
    payload: serviceDescriptionId,
});

//
//SERVICES
//

export const addNewService = ({ Brand, Model, Description, PriceFrom, PriceTo }) => async (dispatch) => {
    const [response, data] = await axios
        .post(
            Urls.AddNewServices,
            [
                {
                    Brand,
                    Model,
                    ServiceDescription: Description,
                    PriceFrom,
                    PriceTo,
                },
            ],
            {
                headers: {
                    'x-auth-token': localStorage.getItem('authenticationToken'),
                },
            }
        )
        .then((res) => [res, res?.data.payload])
        .catch((err) => [err]);

    if (response?.status === 200) {
        dispatch(addNewServices(data));
    }

    dispatch(handleNotificationsResponse(response));
};

export const getServices = () => async (dispatch) => {
    const [response, data] = await axios
        .get(Urls.GetAdminServices, {
            headers: {
                'x-auth-token': localStorage.getItem('authenticationToken'),
            },
        })
        .then((res) => [res, res?.data])
        .catch((err) => [err]);

    if (response?.status === 200) {
        dispatch(addNewServices(data));
    } else {
        dispatch(handleNotificationsResponse(response));
    }
};

export const updateService = ({ Id, PriceFrom, PriceTo, Brand, Model, ServiceDescription }) => async (dispatch) => {
    const [response, data] = await axios
        .put(
            `${Urls.EditService}/${Id}`,
            { Id, PriceFrom, PriceTo },
            {
                headers: {
                    'x-auth-token': localStorage.getItem('authenticationToken'),
                },
            }
        )
        .then((res) => [res, res?.data?.payload[0]])
        .catch((err) => [err]);

    if (response?.status === 200) {
        dispatch(replaceService([{ ...data, Brand, Model, ServiceDescription }]));
    }

    dispatch(handleNotificationsResponse(response));
};

export const removeService = (serviceId) => async (dispatch) => {
    const [response, data] = await axios
        .delete(`${Urls.DeleteServices}/${serviceId}`, {
            headers: {
                'x-auth-token': localStorage.getItem('authenticationToken'),
            },
        })
        .then((res) => [res, res?.data?.payload[0]])
        .catch((err) => [err]);

    if (response?.status === 200) {
        dispatch(deleteService(data.Id));
    }
    dispatch(handleNotificationsResponse(response));
};

const addNewServices = (services) => ({
    type: ADD_NEW_SERVICES,
    payload: services,
});

const replaceService = (service) => ({
    type: UPDATE_SERVICE,
    payload: service,
});

const deleteService = (serviceId) => ({
    type: DELETE_SERVICE,
    payload: serviceId,
});

export const setSelectedServiceId = (serviceId) => ({
    type: SET_SELECTED_SERVICE,
    payload: serviceId,
});

//
//COMMON
//
export const getAllPriceListData = () => async (dispatch) => {
    try {
        const { status, data } = await axios.get(Urls.GetAllPriceListData, {
            headers: {
                'x-auth-token': localStorage.getItem('authenticationToken'),
            },
        });
        if (status === 200) {
            batch(() => {
                dispatch(addNewBrands(data?.Brands));
                dispatch(addNewModels(data?.Models));
                dispatch(addNewServiceDescriptions(data?.ServiceDescriptions));
                dispatch(addNewServices(data?.Services));
            });
        }
    } catch (e) {
        dispatch(handleNotificationsResponse(e));
    }
};

//
//GENERAL
//
export const setPriceListDrawerToEditMode = (data) => ({
    type: SET_PRICE_LIST_DRAWER_MODE,
    payload: data,
});

export const togglePriceListDrawer = (shouldDrawerOpen) => ({
    type: TOGGLE_PRICE_LIST_DRAWER,
    payload: shouldDrawerOpen,
});
