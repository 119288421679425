import React, { useEffect } from 'react';
import { List, Typography } from 'antd';
import _ from 'lodash';

const MobileModelsCardListView = ({ mobileModels, selectedBrand, getModelsForBrand, onSelectedModelClicked }) => {
    useEffect(() => {
        getModelsForBrand(selectedBrand);
    }, [selectedBrand]);

    return (
        <List
            dataSource={_.sortBy(mobileModels, (model) => model?.Name)}
            bordered
            renderItem={(model) => (
                <List.Item onClick={() => onSelectedModelClicked(model)} style={{ cursor: 'pointer' }}>
                    <Typography.Text>{model?.Name}</Typography.Text>
                </List.Item>
            )}
        />
    );
};

export default React.memo(MobileModelsCardListView);
