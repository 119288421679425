import {FILL_NEWS_FEEDS_TO_DISPLAY} from "./news-feeds-actions";
import {uniq} from "ramda";

const initialNewsFeedsState = {
    newsFeeds: []
}

export const newsFeedsReducer = (state = initialNewsFeedsState, action) => {
    switch (action.type){
        case FILL_NEWS_FEEDS_TO_DISPLAY: {
            if (!action.payload){
                return state;
            }
            
            return {
                ...state,
                newsFeeds: uniq([...state.newsFeeds, ...action.payload])
            }
        }
        default:
            return state;
    }
}