import React, {useEffect, useRef} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faFacebook} from "@fortawesome/free-brands-svg-icons"
import gsap from "gsap";
import style from './navigation-mobile.module.scss'
import {sections} from "../../layout/layout-view";

const NavigationMobileView = ({isHamburgerClicked, toggleNavigationState}) => {

    const navigation = useRef(null);
    const navigationFooter = useRef(null);

    const sectionHeight =
        document.body.scrollHeight / sections.length;

    const hideMenu = () => {
        toggleNavigationState()
    }
    const scrollToSection = (sectionHeight, index) => {
        window.scroll(0, index * (sectionHeight + 1))
    }

    useEffect(() => {
        gsap.set(navigation.current, {autoAlpha: 0});
        gsap.set(navigationFooter.current,{autoAlpha:0})
    }, [])

    useEffect(() => {
        if (isHamburgerClicked) {
            const tl = gsap.timeline();
            tl.fromTo(
                navigation.current,
                {x: "100%"},
                {duration: 0.2, x: "-=100%", autoAlpha: 1})
                .fromTo(".navigationElement", {x: "-100vw", autoAlpha: 0}, {
                    autoAlpha: 1,
                    duration: 0.2,
                    x: "+=100vw",
                    stagger: {amount: 0.3},
                })
                .fromTo(navigationFooter.current,{x: '0'},{x:"0", duration: 0.3, autoAlpha: 1, ease: "power1.in" })

        } else {
            gsap.to(navigation.current, {
                duration: 0.2,
                x: "+=100%",
                autoAlpha: 0,
                ease: "power3.inOut",
            });

            gsap.to(navigationFooter.current,{
                autoAlpha:0
            })
        }
    }, [isHamburgerClicked])


    return (
        <div ref={navigation} className={style.wrapper}>
            <nav className={style.navigation}>
                <ul className={style.list}>
                    {sections.map((section, index) => (
                        <li className={'navigationElement'} onClick={() => {
                            scrollToSection(sectionHeight, index);
                            hideMenu();
                        }}>{section.title}</li>
                    ))}
                </ul>
                <div ref={navigationFooter} className={style.navigationFooter}>
                    <a target="_blank" href={process.env.REACT_APP_FACEBOOK_URL}>
                        <FontAwesomeIcon icon={faFacebook}/>
                        <span>Nasz Facebook</span>
                    </a>
                </div>
            </nav>
        </div>
    )
}

export default NavigationMobileView