import React from 'react';
import style from './footer.module.scss';

const Footer = () => {
    return (
        <footer className={style.footerContainer}>
            <span>
                {`Made with \u2764\uFE0F by `}
                <a target={'_blank'} href="https://www.linkedin.com/in/micha%C5%82-ry%C5%9Bkiewicz-b3b751194/">
                    Michał Ryśkiewicz{' '}
                </a>
                {`& `}
                <a target={'_blank'} href="https://www.linkedin.com/in/bowske/">
                    Adam Borowski
                </a>
            </span>
        </footer>
    );
};

export default React.memo(Footer);
