import {
    CLEAR_EDITED_USER,
    DELETE_USER,
    SET_EDITED_USER,
    FILL_USERS_LIST,
    TOGGLE_USERS_LIST_DRAWER, EDIT_SELECTED_USER
} from "./users-list-admin-actions";
import {uniq} from 'ramda';
import formatDate from "../../../../common/format-date";

const initialUsersListState = {
    users: [],
    isUsersListDrawerOpen: false,
    editedUser: undefined,
}

export const usersListRootReducer = (state = initialUsersListState, action) => {
    switch(action.type){
        case FILL_USERS_LIST: {
            return {
                ...state,
                users: uniq([...action.payload, ...state.users])
            };
        }
        case TOGGLE_USERS_LIST_DRAWER: {
            return {
                ...state,
                isUsersListDrawerOpen: action.payload,
            };
        }
        case DELETE_USER: {
            return{
                ...state,
                users: state.users.filter((user) => user.Id !== action.payload)
            };
        }
        case SET_EDITED_USER: {
            return{
                ...state,
                editedUser: action.payload
            }
        }
        case CLEAR_EDITED_USER: {
            return {
                ...state,
                editedUser: undefined
            }
        }
        case EDIT_SELECTED_USER: {
            const newUsers = state.users.map((user) => {
                if(user.Id === action.payload.userId){
                    return {
                        ...user,
                        LastEditedTime:  formatDate(action.payload.LastEditedTime)
                    }
                }
                return user;
            })
            return {
                ...state,
                users: newUsers
            }
        }
        default:
            return state;
    }
}