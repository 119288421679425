import React, {memo, useEffect} from "react";
import ServicesListView from "../../services-list/services-list-view";

const MobileServicesListView = ({ services, selectedMobile, fetchServices}) => {
    useEffect(() => {
        if(selectedMobile){
            fetchServices(selectedMobile)
        }
    }, [selectedMobile])
    return (
        <ServicesListView services={services} />
    )
}

export default memo(MobileServicesListView)