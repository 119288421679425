import React, { memo, useEffect } from 'react';
import MobileBrandsCardListConnected from '../mobile-brands-card-list/mobile-brands-card-list-connected';
import MobileModelsCardListConnected from '../mobile-models-card-list/mobile-models-card-list-connected';
import ServicesListConnected from '../mobile-services-list/mobile-services-list-connected';
import Carousel from '../../carousel/carousel';
import usePrevious from '../../../common/hooks/usePrevious';
import { debug } from 'prettier/doc';

const slides = [<MobileBrandsCardListConnected />, <MobileModelsCardListConnected />, <ServicesListConnected />];

const PriceListCarouselView = ({ selectedBrand, selectedMobile, clearSelectedValues }) => {
    useEffect(() => {
        return () => {
            clearSelectedValues();
        };
    }, []);

    return (
        <>
            <Carousel slides={slides} selectedMobile={selectedMobile} selectedBrand={selectedBrand} />
            <a href={`${process.env.REACT_APP_BACKEND_URL}/images-information`}>Informacje o zdjęciach</a>
        </>
    );
};

export default memo(PriceListCarouselView);
