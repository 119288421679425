import { memo } from 'react';
import style from './contact-element.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ContactElement = ({ icon, title, description }) => {
    return (
        <div className={style.elemWrapper}>
            <div className={style.elemTitle}>
                <div className={style.icon}>
                    <FontAwesomeIcon icon={icon} />
                </div>
                <span className={style.iconTitle}>{title}</span>
            </div>
            {Array.isArray(description) ? (
                description?.map((text, index) => <span key={index}>{text}</span>)
            ) : (
                <span>{description}</span>
            )}
        </div>
    );
};

export default memo(ContactElement);
