import React from 'react';

const ServiceLocalization = () => {
    return(
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2334.395433016714!2d16.195063416000757!3d54.190782118824174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4701cd61225d461d%3A0x20552e7cffdcdd0c!2sNorth%20Service%20-%20Serwis%20telefon%C3%B3w%20kom%C3%B3rkowych%2C%20wymiana%20zbitych%20szybek%20i%20wy%C5%9Bwietlaczy!5e0!3m2!1spl!2spl!4v1612705162148!5m2!1spl!2spl"
            style={{width: '100%', height: '100%', border: '1px solid #d9d9d9'}}
            aria-hidden="false"
            tabIndex="0" />
    )
}

export default React.memo(ServiceLocalization);