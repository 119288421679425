import NavigationDesktopView from "./navigation-desktop-view";
import {connect} from "react-redux";


const mapStateToProps = ({hamburger}) => ({
    isHamburgerClicked: hamburger.isHamburgerClicked
})

const NavigationDesktopConnected = connect(mapStateToProps)(NavigationDesktopView)

export default NavigationDesktopConnected