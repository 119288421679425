import { combineReducers } from "redux"
import { priceListRootReducer } from "./components/price-list/state/price-list-reducer"
import { notificationsRootReducer } from "./common/notifications/notifications-reducer"
import { menuHamburgerReducer } from "./components/hamburger/state/menu-hamburger-reducer";
import managementReducer from './admin/admin-root-reducer';
import { newsFeedsReducer } from "./components/news-feeds/state/news-feeds-reducer";

export default combineReducers({
    priceList: priceListRootReducer,
    notifications: notificationsRootReducer,
    newsFeeds: newsFeedsReducer,
    hamburger: menuHamburgerReducer,
    management: managementReducer,
});
