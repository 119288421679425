import { connect } from 'react-redux';
import {chosenBrand, fetchDistinctBrands} from '../state/price-list-actions';
import MobileBrandsCardListView from "./mobile-brands-card-list-view";

const mapStateToProps = ({ priceList: { mobileBrands } }) => ({
    mobileBrands,
});

const mapDispatchToProps = (dispatch) => ({
    getDistinctBrands: () => dispatch(fetchDistinctBrands()),
    onSelectedBrandClicked: (name) => dispatch(chosenBrand(name)),
});

const MobileBrandsCardListConnected = connect(mapStateToProps, mapDispatchToProps)(MobileBrandsCardListView);

export default MobileBrandsCardListConnected;
