import axios from 'axios';
import Urls from '../../../constant/urls';
import { addNotification } from '../../../common/notifications/notifications-action';

export const FILL_BRANDS = 'FILL_BRANDS';
export const SELECTED_BRAND = 'SELECTED_BRAND';
export const FILL_MODELS = 'FILL_MODELS';
export const SELECTED_MODEL = 'SELECTED_MODEL';
export const FILL_SERVICES = 'FILL_SERVICES';
export const CLEAR_SELECTED_BRANDS = 'CLEAR_SELECTED_BRANDS';
export const CLEAR_SELECTED_MOBILE = 'CLEAR_SELECTED_MOBILE';

export const fetchDistinctBrands = () => async (dispatch) => {
    const [response, brands] = await axios
        .get(Urls.Brands)
        .then((res) => [res, res.data])
        .catch((error) => [error]);

    if (response?.status === 200) {
        dispatch(fillBrands(brands));
    }

    dispatch(addNotification(response.response?.statusText, response.response?.status));
};

export const getModels = (brand) => async (dispatch) => {
    if (brand?.Id) {
        const [response, models] = await axios
            .get(`${Urls.Models}/${brand?.Id}`)
            .then((res) => [res, res.data])
            .catch((err) => [err]);

        if (response?.status === 200) {
            dispatch(fillModels(models));
        }

        dispatch(addNotification(response.response?.statusText, response.response?.status));
    }
};

export const getServices = (model) => async (dispatch) => {
    if (model?.Id) {
        const [response, services] = await axios
            .get(`${Urls.Services}/${model?.Id}`)
            .then((res) => [res, res.data])
            .catch((err) => [err]);

        if (response?.status === 200) {
            dispatch(fillServices(services));
        }
        dispatch(addNotification(response.response?.statusText, response.response?.status));
    }
};

export const clearSelectedBrands = () => ({
    type: CLEAR_SELECTED_BRANDS,
});

export const clearSelectedMobile = () => ({
    type: CLEAR_SELECTED_MOBILE,
});

export const chosenModel = (model) => ({
    type: SELECTED_MODEL,
    payload: model,
});

export const chosenBrand = (brand) => ({
    type: SELECTED_BRAND,
    payload: brand,
});

export const fillBrands = (data) => ({
    type: FILL_BRANDS,
    payload: data,
});

export const fillModels = (models) => ({
    type: FILL_MODELS,
    payload: models,
});

export const fillServices = (services) => ({
    type: FILL_SERVICES,
    payload: services,
});
