import React, { useState, useEffect } from 'react';

export const useWindowResize = () => {
    const [width, setWidth] = useState(window?.innerWidth);
    const [height, setHeight] = useState(window?.innerHeight);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window?.innerWidth);
            setHeight(window?.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    return { width, height };
};
