import axios from "axios";
import Urls from "../../../../constant/urls";
import {handleNotificationsResponse} from "../../../../common/notifications/notifications-action";

export const TOGGLE_NEWS_FEED_DRAWER = 'TOGGLE_NEWS_FEED_DRAWER';
export const FILL_NEWS_FEEDS = 'FILL_NEWS_FEEDS';
export const REMOVE_NEWS_FEED = 'REMOVE_NEWS_FEED';
export const UPDATE_NEWS_FEED = 'UPDATE_NEWS_FEED';
export const SET_EDITED_NEWS_FEED = 'SET_EDITED_NEWS_FEED';
export const CLEAR_EDITED_NEWS_FEED = 'CLEAR_EDITED_NEWS_FEED';

export const addNewPost = (data) => async (dispatch) => {
    const [response, newsFeed] = await axios
        .post(Urls.AddNewsFeed, data, {
            headers: {
                'x-auth-token': localStorage.getItem('authenticationToken'),
            }})
        .then((res) => [res, [res.data[0]]])
        .catch((error) => [error])


    if(response?.status === 200) {
        dispatch(fillNewsFeeds(newsFeed))
    }
    dispatch(handleNotificationsResponse(response))
}

export const fetchAdminNewsFeeds = () => async (dispatch) => {
    const [response, newsFeeds] = await axios
        .get(Urls.GetNewsFeeds, {
            headers: {
                'x-auth-token': localStorage.getItem('authenticationToken'),
            }})
        .then((res) => [res, res.data])
        .catch(error => [error]);
    
    dispatch(fillNewsFeeds(newsFeeds))
}

export const deleteNewsFeed = (newsFeedId) => async (dispatch) => {
    const [response] = await axios
        .delete(`${Urls.DeleteNewsFeed}/${newsFeedId}`,{
            headers: {
                'x-auth-token': localStorage.getItem('authenticationToken'),
            }})
        .then((res) => [res])
        .catch((error) => [error]);


    if (response?.status === 200){
        dispatch(removeNewsFeed(newsFeedId));
    }
    dispatch(handleNotificationsResponse(response));
}

export const editNewsFeed = (newsFeedData) => async (dispatch) => {

    const [response, modifiedData] = await axios
        .put(`${Urls.EditNewsFeed}/${newsFeedData.Id}`, newsFeedData, {
            headers: {
                'x-auth-token': localStorage.getItem('authenticationToken'),
            }})
        .then((res) => [res,  res.data[0]])
        .catch((err) => [err]);


    if (response?.status === 200){
        dispatch(updateNewsFeed({...modifiedData, Id: newsFeedData.Id}));
    }
    dispatch(handleNotificationsResponse(response));
}

export const clearEditedNewsFeed = () => ({
    type: CLEAR_EDITED_NEWS_FEED
})

export const setEditedNewsFeed = (newsFeed) => ({
    type: SET_EDITED_NEWS_FEED,
    payload: newsFeed
})

export const updateNewsFeed = (newsFeed) => ({
    type: UPDATE_NEWS_FEED,
    payload: newsFeed
})

export const removeNewsFeed = (newsFeedId) => ({
    type: REMOVE_NEWS_FEED,
    payload: newsFeedId
})

export const fillNewsFeeds = (newsFeeds) => ({
    type: FILL_NEWS_FEEDS,
    payload: newsFeeds
})

export const toggleNewsFeedDrawer = (shouldDrawerOpen) => ({
    type: TOGGLE_NEWS_FEED_DRAWER,
    payload: shouldDrawerOpen
})

