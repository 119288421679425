import React, { memo } from 'react';
import { Card } from 'antd';
import style from './card-list.module.scss';

const { Meta } = Card;

const CardListView = ({ data, onClick, cardWithImage }) => {
    return (
        <div className={style.container}>
            {data?.map((value, index) => {
                return (
                    <Card
                        hoverable
                        style={{ width: '200px', height: '200px' }}
                        onClick={() => onClick(value)}
                        key={index}
                        cover={
                            cardWithImage ? (
                                <img
                                    style={{ maxHeight: '150px', padding: '10px' }}
                                    key={value?.Name}
                                    alt={value?.Name}
                                    src={`${process.env.REACT_APP_STATIC_FILES}${value?.ImgUrl}`}
                                />
                            ) : null
                        }>
                        <Meta style={{ minHeight: '20px', textAlign: 'center' }} title={value?.Name} />
                    </Card>
                );
            })}
        </div>
    );
};

export default memo(CardListView);
