import { notification } from 'antd';
export const ADD_ERROR = 'ADD_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export const addNotification = (status, message) => {
    return {
        type: ADD_ERROR,
        payload: {
            message,
            status,
        },
    };
};

export const clearNotification = () => ({
    type: CLEAR_ERROR,
});

export const handleNotificationsResponse = (response) => (dispatch) => {
    let responseMessage, responseStatus;

    if (response?.status === 200) {
        responseMessage = response?.data?.message || response?.data?.payload?.message || response?.statusText;
        responseStatus = response?.status;
    } else {
        responseMessage = response.response?.data?.message || response.response?.statusText;
        responseStatus = response.response?.status;
    }

    if (responseStatus === 200) {
        notification.success({
            message: responseStatus,
            description: responseMessage,
            placement: 'topLeft',
        });
    } else {
        notification.error({
            message: responseStatus,
            description: responseMessage,
            placement: 'topLeft',
        });
    }

    dispatch(addNotification(responseStatus, responseMessage));
};
