import {
    ADD_NEW_BRANDS,
    SET_SELECTED_BRAND,
    TOGGLE_PRICE_LIST_DRAWER,
    DELETE_BRAND,
    UPDATE_BRANDS,
    ADD_NEW_MODELS,
    SET_SELECTED_MODEL,
    DELETE_MODEL,
    UPDATE_MODEL,
    ADD_SERVICE_DESCRIPTIONS,
    SET_SELECTED_SERVICE_DESCRIPTION,
    DELETE_SERVICE_DESCRIPTION,
    UPDATE_SERVICE_DESCRIPTION,
    ADD_NEW_SERVICES,
    DELETE_SERVICE,
    SET_SELECTED_SERVICE,
    SET_PRICE_LIST_DRAWER_MODE,
    UPDATE_SERVICE,
} from './price-list-admin-actions';
import _ from 'lodash';

export const priceListInitialState = {
    brands: [],
    selectedBrandId: undefined,
    models: [],
    selectedModelId: undefined,
    serviceDescriptions: [],
    selectedServiceDescriptionId: undefined,
    services: [],
    selectedServiceId: undefined,
    isPriceListDrawerOpen: false,
    isPriceListDrawerInEditMode: false,

    editedService: undefined,
};

export const priceListReducer = (state = priceListInitialState, action) => {
    switch (action.type) {
        //
        //BRANDS
        //
        case ADD_NEW_BRANDS: {
            return {
                ...state,
                brands: _.uniqBy([...state.brands, ...action.payload], 'Id'),
            };
        }
        case UPDATE_BRANDS: {
            return {
                ...state,
                brands: state.brands?.map((brand) => action.payload?.find((o) => o.Id === brand.Id) || brand),
            };
        }
        case DELETE_BRAND: {
            return {
                ...state,
                brands: _.filter(state.brands, (brand) => brand.Id !== action.payload),
            };
        }
        case SET_SELECTED_BRAND: {
            return {
                ...state,
                selectedBrandId: action.payload,
            };
        }
        //
        //MODELS
        //
        case ADD_NEW_MODELS: {
            return {
                ...state,
                models: _.uniqBy([...state.models, ...action.payload], 'Id'),
            };
        }
        case UPDATE_MODEL: {
            return {
                ...state,
                models: state.models?.map((model) => action.payload?.find((o) => o.Id === model.Id) || model),
            };
        }
        case DELETE_MODEL: {
            return {
                ...state,
                models: _.filter(state.models, (model) => model.Id !== action.payload),
            };
        }
        case SET_SELECTED_MODEL: {
            return {
                ...state,
                selectedModelId: action.payload,
            };
        }
        //
        //SERVICE DESCRIPTIONS
        //
        case ADD_SERVICE_DESCRIPTIONS: {
            return {
                ...state,
                serviceDescriptions: _.uniqBy([...state.serviceDescriptions, ...action.payload], 'Id'),
            };
        }
        case UPDATE_SERVICE_DESCRIPTION: {
            return {
                ...state,
                serviceDescriptions: state.serviceDescriptions?.map(
                    (serviceDescription) =>
                        action.payload?.find((o) => o.Id === serviceDescription.Id) || serviceDescription
                ),
            };
        }
        case DELETE_SERVICE_DESCRIPTION: {
            return {
                ...state,
                serviceDescriptions: _.filter(
                    state.serviceDescriptions,
                    (serviceDescription) => serviceDescription.Id !== action.payload
                ),
            };
        }
        case SET_SELECTED_SERVICE_DESCRIPTION: {
            return {
                ...state,
                selectedServiceDescriptionId: action.payload,
            };
        }
        //
        // SERVICES
        //
        case ADD_NEW_SERVICES: {
            return {
                ...state,
                services: _.uniqBy([...state.services, ...action.payload], 'Id'),
            };
        }
        case UPDATE_SERVICE: {
            return {
                ...state,
                services: state.services?.map((service) => action.payload?.find((o) => o.Id === service.Id) || service),
            };
        }
        case DELETE_SERVICE: {
            return {
                ...state,
                services: _.filter(state.services, (service) => service.Id !== action.payload),
            };
        }
        case SET_SELECTED_SERVICE: {
            return {
                ...state,
                selectedServiceId: action.payload,
            };
        }
        //
        //GENERAL
        //
        case SET_PRICE_LIST_DRAWER_MODE: {
            return {
                ...state,
                isPriceListDrawerInEditMode: action.payload,
            };
        }
        case TOGGLE_PRICE_LIST_DRAWER: {
            return {
                ...state,
                isPriceListDrawerOpen: action.payload,
            };
        }
        default:
            return state;
    }
};
