const Urls = {
    Brands: '/price-list/brands',
    Models: '/price-list/models',
    Services: '/price-list/services',

    UploadFile: '/price-list/file-upload',
    SignIn: '/portal/admin-page/',
    VerifyToken: '/portal/admin-page/is-token-valid',
    GetUsers: '/portal/admin-page/users-list',
    AddUser: '/portal/admin-page/register',
    DeleteUser: '/portal/admin-page/remove',
    EditUser: '/portal/admin-page/edit',
    AddNewsFeed: '/news-feed/add-news-feed',
    GetNewsFeeds: '/news-feed/',
    DeleteNewsFeed: '/news-feed/delete-news-feed',
    EditNewsFeed: '/news-feed/edit-news-feed',
    GetNewsFeedsForUsers: '/news-feed/news-to-display',

    AddNewBrands: '/price-list/new-brands',
    GetAdminBrands: '/price-list/admin-brands',
    DeleteBrands: '/price-list/delete-brand',
    EditBrands: '/price-list/edit-brand',

    AddNewModels: '/price-list/new-models',
    GetAdminModels: '/price-list/admin-models',
    DeleteModels: '/price-list/delete-model',
    EditModels: '/price-list/edit-model',

    AddNewServiceDescriptions: '/price-list/new-service-descriptions',
    GetAdminServiceDescriptions: '/price-list/admin-service-descriptions',
    DeleteServiceDescriptions: '/price-list/delete-service-description',
    EditServiceDescriptions: '/price-list/edit-service-description',

    AddNewServices: '/price-list/new-services',
    GetAdminServices: '/price-list/services',
    DeleteServices: '/price-list/delete-service',
    EditService: '/price-list/edit-service',

    GetAllPriceListData: '/price-list/search-in-services',
};

export default Urls;
