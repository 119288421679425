import { AUTHENTICATE } from './sign-in-actions';

const initialState = {
    isAdminAuthenticated: false,
};

export const signInReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTHENTICATE: {
            return {
                ...state,
                isAdminAuthenticated: true,
            };
        }
        default:
            return state;
    }
};
