import {
    CLEAR_EDITED_NEWS_FEED,
    FILL_NEWS_FEEDS,
    REMOVE_NEWS_FEED,
    SET_EDITED_NEWS_FEED,
    TOGGLE_NEWS_FEED_DRAWER, UPDATE_NEWS_FEED
} from "./news-feed-actions";
import {uniq} from "ramda";

const initialNewsFeedState = {
    isNewsFeedDrawerOpen: false,
    newsFeeds: [],
    editedNewsFeed: undefined
}

export const newsFeedReducer = (state = initialNewsFeedState, action) => {
    switch(action.type){
        case TOGGLE_NEWS_FEED_DRAWER: {
            return{
                ...state,
                isNewsFeedDrawerOpen: action.payload
            }
        }
        case FILL_NEWS_FEEDS: {
            if (!action.payload){
                return state;
            }

            return {
                ...state,
                newsFeeds: uniq([...state.newsFeeds, ...action.payload])
            }
        }
        case REMOVE_NEWS_FEED: {
            const newNewsFeeds = [...state.newsFeeds];
            const indexOfElementToRemove = newNewsFeeds.findIndex(x => x.Id === action.payload);
            newNewsFeeds.splice(indexOfElementToRemove, 1);
            return {
                ...state,
                newsFeeds: newNewsFeeds
            }
        }
        case UPDATE_NEWS_FEED: {
            const newNewsFeeds = state.newsFeeds?.map((newsFeed) => {
                if (newsFeed.Id === action.payload.Id){
                    return {
                        ...newsFeed,
                        ...action.payload,
                    }
                }
                return newsFeed;
            })

            return{
                ...state,
                newsFeeds: newNewsFeeds
            }
        }
        case SET_EDITED_NEWS_FEED: {
            return {
                ...state,
                editedNewsFeed: action.payload
            }
        }
        case CLEAR_EDITED_NEWS_FEED: {
            return {
                ...state,
                editedNewsFeed: undefined
            }
        }
        default:
            return state;
    }
}