import { combineReducers } from 'redux';
import { signInReducer } from './sign-in/state/sign-in-reducer';
import { priceListReducer } from './sections/price-list/state/price-list-admin-reducer';
import { usersListRootReducer } from "./sections/users-list/state/users-list-admin-reducer";
import { newsFeedReducer } from "./sections/news-feed/state/news-feed-reducer";

export default combineReducers({
    authentication: signInReducer,
    adminPriceList: priceListReducer,
    newsFeeds: newsFeedReducer,
    usersList: usersListRootReducer,
});
