import NavigationMobileView from "./navigation-mobile-view";
import {connect} from "react-redux";
import {toggleHamburgerMenu} from "../hamburger/state/menu-hamburger-actions";

const mapStateToProps = (state) => ({
    isHamburgerClicked: state.hamburger.isHamburgerClicked
})
const mapDispatchToProps = dispatch => ({
    toggleNavigationState: () => dispatch(toggleHamburgerMenu())
})

const NavigationMobileConnected = connect(mapStateToProps, mapDispatchToProps)(NavigationMobileView)

export default NavigationMobileConnected