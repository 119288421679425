import Urls from "../../../constant/urls";
import axios from "axios";

export const FILL_NEWS_FEEDS_TO_DISPLAY = 'FILL_NEWS_FEEDS_TO_DISPLAY';


export const fetchNewsFeedsForUsers = (amountOfNewsFeeds) => async (dispatch) => {
    const [response, newsFeeds] = await axios
        .get(`${Urls.GetNewsFeedsForUsers}/${amountOfNewsFeeds}`)
        .then((res) => [res,  res.data])
        .catch((error) => [error])
    
    dispatch(fillNewsFeedsToDisplay(newsFeeds))
}

export const fillNewsFeedsToDisplay = (newsFeeds) => ({
    type: FILL_NEWS_FEEDS_TO_DISPLAY,
    payload: newsFeeds
})