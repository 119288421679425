import {batch, connect} from 'react-redux'
import PriceListCarouselView from "./price-list-carousel-view";
import {clearSelectedBrands, clearSelectedMobile} from "../state/price-list-actions";

const mapStateToProps = ({priceList: {selectedBrand, selectedMobile}}) => ({
    selectedBrand,
    selectedMobile
})

const mapDispatchToProps = (dispatch) => ({
    clearSelectedValues: () => batch(() => {
        dispatch(clearSelectedBrands())
        dispatch(clearSelectedMobile())
    })
})

const PriceListCarouselConnected = connect(mapStateToProps, mapDispatchToProps)(PriceListCarouselView)

export default PriceListCarouselConnected