import { memo } from 'react';
import style from './landing.module.scss';
import Calendar from '../../assets/Calendar.svg';
import PriceCut from '../../assets/Price Cut.svg';
import Phone from '../../assets/Smartphone.svg';
import Like from '../../assets/Like.svg';
import { useWindowResize } from '../../common/hooks/useWindowResize';
import Breakpoints from '../../constant/breakpoints';
import { Col, Row } from 'antd';
import LandingTile from './landing-tile/landing-tile';

export const landingItems = [
    {
        icon: Phone,
        alt: 'phone icon',
        description: 'Naprawa iOS i Android',
    },
    {
        icon: Calendar,
        alt: 'calendar icon',
        description: 'Szybki czas wykonania usługi',
    },
    {
        icon: PriceCut,
        alt: 'price cut icon',
        description: 'Przystępne ceny',
    },
    {
        icon: Like,
        alt: 'like icon',
        description: 'Doświadczenie i zadowoleni klienci',
    },
];

const Landing = () => {
    const { width } = useWindowResize();

    const biggerBoxSpan = width <= Breakpoints.mobile ? 24 : 16;
    const smallerBoxSpan = width <= Breakpoints.mobile ? 24 : 7;
    const offset = width <= Breakpoints.mobile ? 0 : 1;
    return (
        <div className={style.externalContainer}>
            <div className={style.container}>
                <Row gutter={[0, 16]}>
                    {landingItems?.map((landingItem, index) => {
                        switch (index % 4) {
                            case 0: {
                                return (
                                    <Col className={style.box} span={biggerBoxSpan}>
                                        <LandingTile {...landingItem} />
                                    </Col>
                                );
                            }
                            case 1: {
                                return (
                                    <Col className={style.box} offset={offset} span={smallerBoxSpan}>
                                        <LandingTile {...landingItem} />
                                    </Col>
                                );
                            }
                            case 2: {
                                return (
                                    <Col className={style.box} span={smallerBoxSpan}>
                                        <LandingTile {...landingItem} />
                                    </Col>
                                );
                            }
                            case 3: {
                                return (
                                    <Col className={style.box} offset={offset} span={biggerBoxSpan}>
                                        <LandingTile {...landingItem} />
                                    </Col>
                                );
                            }
                        }
                    })}
                </Row>
            </div>
        </div>
    );
};

export default memo(Landing);
