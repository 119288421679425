import { connect } from 'react-redux';
import { chosenModel, getModels } from '../state/price-list-actions';
import MobileBrandsCardListView from './mobile-models-card-list-view';

const mapStateToProps = ({ priceList: { mobileModels, selectedBrand } }) => ({
    mobileModels: mobileModels?.filter((model) => model?.Brand === selectedBrand?.Id),
    selectedBrand,
});

const mapDispatchToProps = (dispatch) => ({
    getModelsForBrand: (brand) => dispatch(getModels(brand)),
    onSelectedModelClicked: (model) => dispatch(chosenModel(model)),
});

const MobileModelsCardListConnected = connect(mapStateToProps, mapDispatchToProps)(MobileBrandsCardListView);

export default MobileModelsCardListConnected;
