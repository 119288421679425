import {toggleHamburgerMenu} from "./state/menu-hamburger-actions";
import {connect} from "react-redux";
import HamburgerView from "./hamburger-view";

export const mapStateToProps = (state) => ({
    isHamburgerClicked: state.hamburger.isHamburgerClicked
})
export const mapDispatchToProps = (dispatch) => ({
    toggleHamburgerMenu: (data) => dispatch(toggleHamburgerMenu(data)),
})

const HamburgerConnected = connect(mapStateToProps, mapDispatchToProps)(HamburgerView)

export default HamburgerConnected