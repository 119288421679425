import { memo } from 'react';

const LandingTile = ({ icon, alt, description }) => {
    return (
        <>
            <img src={icon} alt={alt} />
            <p>{description}</p>
        </>
    );
};

export default memo(LandingTile);
