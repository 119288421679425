import React, {lazy} from 'react';
import ReactDOM from 'react-dom';
import store from './store';
import axios from 'axios';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import {Route, Switch} from "react-router";
import LazyLoad from "./common/lazy-load";
import App from "./app/app";
import 'antd/dist/antd.css';

const SignInConnected = lazy(() => import("./admin/sign-in/sign-in-connected"));
const ProtectedRoute = lazy(() => import("./common/protected-route"));
const AdminLandingPageView = lazy(() => import("./admin/landing-page/landing-page-view"));
const PageNotFound = lazy(() => import("./components/page-not-found/page-not-found"));

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <LazyLoad>
                    <Switch>
                        <Route path='/' exact>
                            <App />
                        </Route>
                        <Route path='/ns-login' exact>
                            <SignInConnected />
                        </Route>
                        <ProtectedRoute path="/admin-portal" exact component={AdminLandingPageView}/>
                        <Route component={PageNotFound} />
                    </Switch>
                </LazyLoad>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
