import React, { memo, useEffect, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { useSwipeable } from 'react-swipeable';
import { useWindowResize } from '../../common/hooks/useWindowResize';
import style from './price-list.module.scss';

const Carousel = ({ slides, moveRight, moveLeft, selectedMobile, selectedBrand }) => {
    const [carouselPosition, setCarouselPosition] = useState(0);
    const { width } = useWindowResize();

    useEffect(() => {
        return () => {
            setCarouselPosition(0);
        };
    }, []);

    useEffect(() => {
        if (selectedBrand || selectedMobile || moveRight) {
            moveSlideRight();
        }
    }, [moveRight, selectedBrand, selectedMobile]);

    useEffect(() => {
        if (moveLeft) {
            moveSlideLeft();
        }
    }, [moveLeft]);

    const moveSlideLeft = () => {
        carouselPosition < 0 && setCarouselPosition(carouselPosition + 100);
    };

    const moveSlideRight = () => {
        carouselPosition > (slides.length - 1) * -100 && setCarouselPosition(carouselPosition - 100);
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: moveSlideLeft,
        onSwipedRight: moveSlideRight,
    });

    return (
        <div className={style.carouselContainer}>
            {width >= 1025 && (
                <button id={style.left} onClick={moveSlideLeft}>
                    <IoIosArrowBack />
                </button>
            )}
            <div className={style.carousel} {...swipeHandlers}>
                {slides.map((slide, index) => (
                    <div key={index} className={style.slide} style={{ transform: `translateX(${carouselPosition}%)` }}>
                        {slide}
                    </div>
                ))}
            </div>
            {width >= 1025 && (
                <button id={style.right} onClick={moveSlideRight}>
                    <IoIosArrowForward />
                </button>
            )}
        </div>
    );
};

export default memo(Carousel);
