import { connect } from 'react-redux';
import MobileServicesListView from './mobile-services-list-view';
import { getServices } from '../state/price-list-actions';

const mapStateToProps = ({ priceList: { services, selectedMobile } }) => ({
    services: services?.filter((service) => service?.Model === selectedMobile?.Id),
    selectedMobile,
});

const mapDispatchToProps = (dispatch) => ({
    fetchServices: (selectedMobile) => dispatch(getServices(selectedMobile)),
});

const MobileServicesListConnected = connect(mapStateToProps, mapDispatchToProps)(MobileServicesListView);

export default MobileServicesListConnected;
