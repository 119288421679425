import React, {useEffect, useState} from 'react';
import LayoutView, { sections } from '../layout/layout-view';
import style from './app.module.scss';
import {useWindowResize} from "../common/hooks/useWindowResize";

const App = () => {
    const {height} = useWindowResize();
    const [appHeight, setAppHeight] = useState(sections.length * (height + 1));

    useEffect(() => {
        setAppHeight(sections.length * (height + 1))
    }, [height])
    

    return (
        <div className={style.app} style={{height: appHeight}}>
            <LayoutView />
        </div>
    );
};

export default React.memo(App);
