import { ADD_ERROR, CLEAR_ERROR } from './notifications-action';

const notificationInitialState = {
    message: '',
    status: null,
};

export const notificationsRootReducer = (state = notificationInitialState, action) => {
    switch (action.type) {
        case ADD_ERROR: {
            const { status, message } = action.payload;
            return {
                ...state,
                message,
                status,
            };
        }
        case CLEAR_ERROR: {
            return {
                ...state,
                message: '',
                status: null,
            };
        }
        default:
            return state;
    }
};
