import axios from 'axios';
import Urls from "../../../../constant/urls";
import {handleNotificationsResponse} from "../../../../common/notifications/notifications-action";
import formatDate from "../../../../common/format-date";

export const FILL_USERS_LIST = 'FILL_USERS_LIST';
export const TOGGLE_USERS_LIST_DRAWER = 'TOGGLE_USERS_LIST_DRAWER';
export const DELETE_USER = 'DELETE_USER';
export const SET_EDITED_USER = 'SET_EDITED_USER';
export const CLEAR_EDITED_USER = 'CLEAR_EDITED_USER';
export const EDIT_SELECTED_USER = 'EDIT_SELECTED_USER';

export const getUsers = () => dispatch => {
    axios
        .get(Urls.GetUsers, {
            headers: {
                'x-auth-token': localStorage.getItem('authenticationToken'),
            },
        })
        .then((res) => {
            if(res?.status === 200){

                const dataToState = res?.data?.map(({Id, Username, CreationTime, LastEditedTime}) => {
                    return {
                        Id,
                        Username,
                        CreationTime: formatDate(CreationTime),
                        LastEditedTime: formatDate(LastEditedTime)
                    }
                })

                dispatch(fillUsersList(dataToState))
            }
        })
        .catch((error) => {
            dispatch(handleNotificationsResponse(error));
        })
}

export const addNewUser = (newUserCredentials) => async (dispatch) => {
    const [response, newUser] = await axios
        .post(Urls.AddUser, newUserCredentials,{
            headers: {
                'x-auth-token': localStorage.getItem('authenticationToken'),
            },
        })
        .then((res) => [res, res.data?.newUser])
        .catch((error) => [error])


    if(response?.status === 200) {
        dispatch(fillUsersList([{
                ...newUser,
                CreationTime: formatDate(newUser.CreationTime),
                LastEditedTime: formatDate(newUser.LastEditedTime)
            }])
        );
    }
    dispatch(handleNotificationsResponse(response))
}

export const deleteUser = ({Id, Username}) => async (dispatch) => {
    const [response, deletedUserId] =  await axios
        .delete(`${Urls.DeleteUser}/${Id}/${Username}`, {
            headers: {
                'x-auth-token': localStorage.getItem('authenticationToken'),
            }})
        .then((res) => [res, res.data[0]])
        .catch((error) => [error])


    if(response?.status === 200) {
        dispatch(deleteUserFromList(deletedUserId));
    }
    dispatch(handleNotificationsResponse(response));
}

export const editUser = ({userId, username, password, newPassword, confirmedNewPassword}) => async (dispatch) => {
    const [response, LastEditedTime, token] =  await axios
        .put(`${Urls.EditUser}/${userId}/${username}`, {password, newPassword, confirmedNewPassword},{
            headers: {
                'x-auth-token': localStorage.getItem('authenticationToken'),
            }})
        .then((res) => [res,res.data.LastEditedTime, res.data.token])
        .catch((error) => [error])

    if(response?.status === 200) {
        localStorage.setItem('authenticationToken', token);
        dispatch(editSelectedUser({userId, LastEditedTime}))
    }
    dispatch(handleNotificationsResponse(response));
}

export const editSelectedUser = ({ userId, LastEditedTime }) => ({
    type: EDIT_SELECTED_USER,
    payload: { userId, LastEditedTime }
})

export const clearEditedUser = () => ({
    type: CLEAR_EDITED_USER
})

export const setEditedUser = (editedUser) => ({
    type: SET_EDITED_USER,
    payload: editedUser
})

export const deleteUserFromList = (userId) => ({
    type: DELETE_USER,
    payload: userId
})

export const toggleUsersListDrawer = (shouldDrawerOpen) => ({
    type: TOGGLE_USERS_LIST_DRAWER,
    payload: shouldDrawerOpen,
})

export const fillUsersList = (usersList) => ({
    type: FILL_USERS_LIST,
    payload: usersList
})
