import {TOGGLE_MENU} from "./menu-hamburger-actions";

const initialHamburgerState = {
    isHamburgerClicked: false
}

export const menuHamburgerReducer = (state = initialHamburgerState, action) => {
    switch (action.type) {
        case TOGGLE_MENU: {
            return {
                ...state,
                isHamburgerClicked: action.payload
            }
        }
        default:
            return state
    }
}