import React, { useEffect } from 'react';
import CardListView from '../../card-list/card-list-view';
import { isEmpty } from 'lodash';

const MobileBrandsCardListView = ({ mobileBrands, onSelectedBrandClicked, getDistinctBrands }) => {
    useEffect(() => {
        if (isEmpty(mobileBrands)) {
            getDistinctBrands();
        }
    }, []);

    return <CardListView data={mobileBrands} onClick={onSelectedBrandClicked} cardWithImage />;
};

export default React.memo(MobileBrandsCardListView);
