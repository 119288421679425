import React from "react"
import style from "./hero-header.module.scss"
import {ReactComponent as Logo} from "../../assets/logo.svg"
import NavigationDesktopConnected from "../navigation-desktop/navigation-mobile-connected";
import Breakpoints from "../../constant/breakpoints";
import {useWindowResize} from "../../common/hooks/useWindowResize";
import HamburgerConnected from "../hamburger/hamburger-connected";

const HeroHeaderView = () => {
    const {width} = useWindowResize();

    return (
        <header className={style.heroHeader}>
            <a href={process.env.REACT_APP_HOME_REDIRECT}>
                <Logo className={style.logo}/>
            </a>
            {width > Breakpoints.tablet && (
            <NavigationDesktopConnected/>)}
            {window.location.pathname === process.env.REACT_APP_HOME_REDIRECT && (
                <HamburgerConnected/>
            )}
        </header>
    )
}
export default React.memo(HeroHeaderView)
