import React from "react"
import style from "./hamburger.module.scss"

const HamburgerView = ({ isHamburgerClicked, toggleHamburgerMenu }) => {
    return (
            <button onClick={() => toggleHamburgerMenu(!isHamburgerClicked)} className={`${style.hamburger} ${isHamburgerClicked && style.hamburgerActive }`}>
                <span className={style.hamburger__box}>
                    <span className={style.hamburger__inner}></span>
                </span>
            </button>
    )
}

export default React.memo(HamburgerView)





