import {
    CLEAR_SELECTED_BRANDS,
    CLEAR_SELECTED_MOBILE,
    FILL_BRANDS,
    FILL_MODELS,
    FILL_SERVICES,
    SELECTED_BRAND,
    SELECTED_MODEL,
} from './price-list-actions';
import _ from 'lodash';

const initialPriceListState = {
    mobileBrands: [],
    selectedBrand: undefined,
    mobileModels: [],
    selectedMobile: undefined,
    services: [],
};

export const priceListRootReducer = (state = initialPriceListState, action) => {
    switch (action.type) {
        case FILL_BRANDS: {
            return {
                ...state,
                mobileBrands: _.uniqBy([...state.mobileBrands, ...action.payload], 'Id'),
            };
        }
        case SELECTED_BRAND: {
            return {
                ...state,
                selectedBrand: action.payload,
            };
        }
        case FILL_MODELS: {
            if (action.payload) {
                return {
                    ...state,
                    mobileModels: _.uniqBy([...state.mobileModels, ...action.payload], 'Id'),
                };
            }
            return state;
        }
        case SELECTED_MODEL: {
            return {
                ...state,
                selectedMobile: action.payload,
            };
        }
        case FILL_SERVICES: {
            if (action.payload) {
                return {
                    ...state,
                    services: _.uniqBy([...state.services, ...action.payload], 'Id'),
                };
            }
            return state;
        }
        case CLEAR_SELECTED_BRANDS: {
            return {
                ...state,
                selectedBrand: undefined,
            };
        }
        case CLEAR_SELECTED_MOBILE: {
            return {
                ...state,
                selectedMobile: undefined,
            };
        }
        default:
            return state;
    }
};
