import React, {useRef, useEffect, useState, lazy, useMemo} from 'react';
import style from './layout-view.module.scss';
import {ReactComponent as Scene} from '../assets/scene.svg';
import ContentContainer from '../components/content-container/content-container';
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import LazyLoad from '../common/lazy-load';
import ServiceLocalization from "../components/service-localization/service-localization";
import NavigationMobileConnected from "../components/navigation-mobile/navigation-mobile-connected";
import Breakpoints from "../constant/breakpoints";
import {useWindowResize} from "../common/hooks/useWindowResize";
import Contact from "../components/contact/contact";
import Landing from "../components/landing/landing";
import Footer from "../components/footer/footer";
import HeroHeaderView from "../components/hero-header/hero-header-view";
import PriceListCarouselConnected from "../components/price-list/price-list-carousel/price-list-carousel-connected";

const NewsFeedsListConnected = lazy(() => import('../components/news-feeds/news-feeds-list-connected'));

export const sections = [
    {
        title: 'O nas',
        component: <LazyLoad children={<Landing/>}/>,
    },

    {
        title: 'Aktualności',
        component: <LazyLoad children={<NewsFeedsListConnected/>}/>,
    },
    {
        title: 'Cennik',
        component: <PriceListCarouselConnected />,
    },
    {
        title: 'Kontakt',
        component: <LazyLoad children={<Contact/>}/>,
    },
    {
    title: 'Mapa',
        component: <LazyLoad children={<ServiceLocalization/>}/>,
    },
]

const LayoutView = () => {

    gsap.registerPlugin(ScrollTrigger);
    const [sectionName, setSectionName] = useState(sections[0]?.title);
    const wrapper = useRef(null);
    const {width} = useWindowResize()

    useEffect(() => {
        const [elements] = wrapper.current.children;
        let sectionIterator = 0;

        const incrementSection = () => {
            sectionIterator++;
            setSectionName(sections[sectionIterator]?.title);
        };

        const decrementSection = () => {
            sectionIterator--;
            setSectionName(sections[sectionIterator]?.title);
        };

        const sectionPuzzles = sections.map((puzzle, index) => elements.getElementById(`puzzle${index}`));
        gsap.set([sectionPuzzles], {autoAlpha: 0});

        sectionPuzzles.forEach((puzzle, index) => {
            gsap.to(puzzle, {
                scrollTrigger: {
                    trigger: 'body',
                    start: () => `${index * (window.innerHeight)}px`,
                    end: () => `+=${window.innerHeight}px`,
                    toggleActions: 'play none none reverse',
                    invalidateRefresh: true,
                    onLeave: incrementSection,
                    onEnterBack: decrementSection,
                },
                autoAlpha: 1,
            });
        });
    }, []);

    return (
        <div className={style.layoutWrapper}>
            <HeroHeaderView/>
            <div className={style.contentWithSvgWrapper}>
                <ContentContainer name={sectionName}>
                    {sections.find((section) => section?.title === sectionName)?.component}
                </ContentContainer>
                <div className={style.svgWrapper} ref={wrapper}>
                    <Scene className={style.heroSvg}/>
                </div>
            </div>
            {width <= Breakpoints.tablet &&
            <NavigationMobileConnected/>}
            <Footer/>
        </div>
    );
};

export default React.memo(LayoutView);
