import React from 'react';
import style from './contact.module.scss';
import {
    faPhoneSquareAlt,
    faEnvelope,
    faHome,
    faFileInvoiceDollar,
    faMoneyCheckAlt,
    faClock,
} from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'antd';
import ContactElement from './contact-element/contact-element';
import { useWindowResize } from '../../common/hooks/useWindowResize';
import Breakpoints from '../../constant/breakpoints';

export const contactSections = [
    {
        title: 'Godziny otwarcia',
        icon: faClock,
        description: ['Poniedziałek - Piątek: 9:00 - 17:00', 'Sobota - Niedziela: Nieczynne'],
    },
    {
        title: 'Nasz adres',
        icon: faHome,
        description: 'Zwycięstwa 137, 75-604 Koszalin',
    },
    {
        title: 'Telefon',
        icon: faPhoneSquareAlt,
        description: '+48 604 605 571',
    },
    {
        title: 'Mail',
        icon: faEnvelope,
        description: 'north@list.pl',
    },
    {
        title: 'NIP/REGON',
        icon: faFileInvoiceDollar,
        description: ['NIP: 6691144910', 'REGON: 330332459'],
    },
    {
        title: 'Dane do przelewu',
        icon: faMoneyCheckAlt,
        description: 'ING - 58 1050 1559 1000 0023 0221 0303',
    },
];

const Contact = () => {
    const { width } = useWindowResize();

    return (
        <div className={style.externalContainer}>
            <div className={style.container}>
                {width >= Breakpoints.mobile ? (
                    <Row gutter={[8, 8]}>
                        <Col className={style.box} span={24}>
                            {<ContactElement {...contactSections[0]} />}
                        </Col>
                        <Col span={16}>
                            <Row>
                                <Col className={style.box} span={24}>
                                    {<ContactElement {...contactSections[1]} />}
                                </Col>
                            </Row>
                            <Row>
                                <Col className={style.box} span={12}>
                                    {<ContactElement {...contactSections[2]} />}
                                </Col>
                                <Col className={style.box} span={12}>
                                    {<ContactElement {...contactSections[3]} />}
                                </Col>
                            </Row>
                        </Col>
                        <Col className={style.box} span={8}>
                            {<ContactElement {...contactSections[4]} />}
                        </Col>

                        <Col className={style.box} span={24}>
                            {<ContactElement {...contactSections[5]} />}
                        </Col>
                    </Row>
                ) : (
                    <Row gutter={[8, 8]}>
                        {contactSections.map((section) => (
                            <Col className={style.box} span={24}>
                                {<ContactElement {...section} />}
                            </Col>
                        ))}
                    </Row>
                )}
            </div>
        </div>
    );
};

export default React.memo(Contact);
