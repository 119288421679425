import React, {useEffect, useRef} from "react"
import style from "./navigation-desktop.module.scss"
import {gsap} from "gsap";
import {sections} from "../../layout/layout-view";

const NavigationDesktopView = ({isHamburgerClicked}) => {
    const navigation = useRef(null);

    const sectionHeight =
        document.body.scrollHeight / sections.length;

    const scrollToSection = (sectionHeight, index) => {
        window.scroll(0, index * (sectionHeight + 1))
    }
    const animateNavigation = () => {
        if (isHamburgerClicked) {
            const tl = gsap.timeline();
            tl.fromTo(
                navigation.current,
                {x: "100%"},
                {duration: 0.7, x: "-=100%", display: 'block', autoAlpha: 1})


        } else {
            gsap.to(navigation.current, {
                duration: 1,
                x: "+=100%",
                display: 'none',
                autoAlpha: 0,
                ease: "power3.inOut",
            })

        }

    }
    useEffect(() => {
        gsap.set(navigation.current, {autoAlpha: 0})
    }, [])

    useEffect(() => {
        animateNavigation()
    }, [isHamburgerClicked])

    return (
        <nav className={style.desktopNavigation} ref={navigation}>
            <ul>
                {sections.map((section, index) => (
                    <li onClick={() => scrollToSection(sectionHeight, index)}>{section.title}</li>
                ))}
            </ul>
        </nav>
    )
}
export default React.memo(NavigationDesktopView)
