import React, {memo} from 'react';
import { Table } from 'antd';

const columns = [
    {
        title: 'Opis',
        dataIndex: 'ServiceDescription',
        key: 'ServiceDescription',
        align: 'center',
    },
    {
        title: 'Cena Od [Zł]',
        dataIndex: 'PriceFrom',
        key: 'PriceFrom',
        align: 'center',
    },
    {
        title: 'Cena Do [Zł]',
        dataIndex: 'PriceTo',
        key: 'PriceTo',
        align: 'center',
    },
];

const ServicesListView = ({ services }) => {
    return (
        <Table bordered={true} columns={columns} dataSource={services} pagination={false} />
    );
};

export default memo(ServicesListView);
